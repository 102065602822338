import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, Button, Stack, Typography, MenuItem } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useTranslation } from '@contexts/translation'

import DatePicker from '@components/form/DatePicker'
import TimePicker from '@components/form/TimePicker'
import Select from '@components/common/Select'

const ActionModal = ({ onConfirm, payload, onClose }) => {
  const { dictionary } = useTranslation()

  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    invoice_date:
      payload?.action === 'paid'
        ? yup
            .string()
            .required(dictionary.required)
            .typeError(dictionary.required)
        : yup.string().nullable(),
    send_on_date:
      payload?.action === 'schedule'
        ? yup
            .date()
            .nullable()
            .typeError(dictionary.required)
            .min(new Date(), dictionary.date_must_be_in_future)
        : yup.string(),
    send_on_time: yup.string(),
    payment_type: yup.string()
  })

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      invoice_date: '',
      send_on_date: '',
      send_on_time: new Date('01-01-1970 ' + '08:00:00'),
      payment_type: ''
    }
  })

  const onSubmit = async values => {
    setLoading(true)

    let data = {}
    if (values.invoice_date)
      data.invoice_date = format(new Date(values.invoice_date), 'dd-MM-yyyy')
    if (values.send_on_date)
      data.send_on_date = format(new Date(values.send_on_date), 'dd-MM-yyyy')
    if (values.send_on_time)
      data.send_on_time = format(new Date(values.send_on_time), 'HH:mm:ss')
    if (payload?.action === 'paid' && values.payment_type) {
      data.type = values.payment_type
    }
    if (payload?.action === 'schedule') {
      data.send_email = true
    }

    if (payload?.action === 'invoice_date' || payload?.action === 'paid') {
      delete data.send_on_date
      delete data.send_on_time
    } else if (payload?.action === 'schedule') {
      delete data.invoice_date
    }

    onConfirm({
      ...data,
      action: payload.action,
      pks: payload.invoices
    })

    setLoading(false)
    onClose()
  }

  const PAYMENT_TYPES = [
    {
      id: 1,
      label: dictionary.cash,
      value: 'E'
    },
    {
      id: 2,
      label: dictionary.check,
      value: 'C'
    },
    {
      id: 3,
      label: dictionary.card,
      value: 'U'
    },
    {
      id: 4,
      label: dictionary.lcr,
      value: 'L'
    },
    {
      id: 5,
      label: dictionary.transfer,
      value: 'V'
    },
    {
      id: 6,
      label: dictionary.deposit,
      value: 'P'
    },
    {
      id: 7,
      label: dictionary.none,
      value: 'S'
    }
  ]

  return (
    <form
      data-cy={'form'}
      style={{ width: '100%' }}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack>
            {payload?.invoices?.map(i => (
              <Typography
                variant="subtitle1"
                key={i.pk}
                data-testid="Typography-e119fd71-710e-4d2c-9d27-8b080d0e9cfc"
              >
                {i.code}
              </Typography>
            ))}
          </Stack>
        </Grid>

        {(payload?.action === 'invoice_date' || payload?.action === 'paid') && (
          <Grid item xs={12}>
            <DatePicker
              name="invoice_date"
              required={payload?.action === 'paid'}
              label={dictionary.invoice_date}
              variant="outlined"
              fullWidth
              control={control}
              error={Boolean(errors.invoice_date)}
              errorMessage={errors.invoice_date && errors.invoice_date.message}
              data_testid="DatePicker-eccf1fca-935e-47e7-a602-7bcd3856afda"
            />
          </Grid>
        )}

        {payload?.action === 'paid' && (
          <Grid item xs={12}>
            <Select
              fullWidth
              name="payment_type"
              label={dictionary.type}
              variant="outlined"
              control={control}
              defaultValue={''}
              error={Boolean(errors.payment_type)}
              errorMessage={errors.payment_type?.message}
              data_testid="Select-aa365026-b181-4066-91c7-20e86af28c16"
            >
              <MenuItem
                value={''}
                data-testid="MenuItem-0d004454-b832-47d5-9a9e-629a3c1ecb49"
              >
                {'----------'}
              </MenuItem>
              {PAYMENT_TYPES.map(({ id, value, label }) => (
                <MenuItem
                  key={id}
                  value={value}
                  data-testid="MenuItem-a4d2ad7e-b1c0-4d61-b2a5-cf7fa4ca08af"
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        {payload?.action === 'schedule' && (
          <>
            <Grid item xs={12}>
              <DatePicker
                required
                name="send_on_date"
                label={dictionary.send_on_date}
                variant="outlined"
                fullWidth
                control={control}
                error={Boolean(errors.send_on_date)}
                errorMessage={
                  errors.send_on_date && errors.send_on_date.message
                }
                data_testid="DatePicker-a0237699-56dd-418f-9faf-ef1f5b593576"
              />
            </Grid>

            <Grid item xs={12}>
              <TimePicker
                name="send_on_time"
                label={dictionary.send_on_time}
                variant="outlined"
                fullWidth
                control={control}
                error={Boolean(errors.send_on_time)}
                errorMessage={
                  errors.send_on_time && errors.send_on_time.message
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} align="right">
          <Button
            type="submit"
            disabled={loading}
            data-testid="Button-760cf7b5-8305-4fb6-a541-262c1089f561"
          >
            {dictionary.save}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ActionModal
