import React, { useEffect, useMemo, useState } from 'react'
import { useFetchData } from '@api/fetchData'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Grid, MenuItem, TextField } from '@mui/material'

import API_URL from '@config/services'
import queryString from '@utils/queryString'
import { useTranslation } from '@contexts/translation'
import { format } from 'date-fns'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import DatePicker from '@components/form/DatePicker'
import Select from '@components/common/Select'
import DateRangePickerCustom from '@components/form/DateRangePicker'
import { createInvoice } from '@api/invoices'
import { useAuth } from '@contexts/auth'
import { LoadingButton } from '@mui/lab'
import { Add } from '@mui/icons-material'
import CommonModal from '@components/common/Modal/CommonModal'
import AccountSettingsBanksForm from '@components/Account/Settings/Form/Banks'
import { checkPerms } from '@utils/checkPerms'

export default function AddInvoiceForm(props) {
  const { operationId, invoicesURL, openInvoice, invoiceType, leaseId } = props
  const { dictionary, t } = useTranslation()
  const { organization, organizationTeam, permissions, operationDetails } =
    useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const manage_banks = checkPerms({
    perm: ['manage_banks'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })

  const [loading, setLoading] = useState(false)
  const [bank, setBank] = useState(operationDetails?.bank)
  const [isAddBank, setIsAddBank] = useState(false)

  //Checker (et si) on n'a pas d'opération
  const { data: operations, isLoading: loadingOperations } = useFetchData(
    !operationId ? API_URL.PROJECTS.GET_PROJECT_LIST_PK : null
  )

  const schema = yup.object().shape({
    operation: !operationId
      ? yup.string().required(dictionary.required)
      : yup.string(),
    lease: yup.string(),
    landlord: yup.string(),
    invoice_date: yup.string().nullable().required(dictionary.required),
    tax_rate: yup
      .string()
      .min(1, dictionary.required)
      .required(dictionary.required),
    billing_option: yup.string().required(dictionary.required),
    language: yup.string().required(dictionary.required),
    bank: yup.string(),
    references: yup.string(),
    // periods: yup.string(),
    start_date: yup.date().nullable().typeError(dictionary.required),
    end_date: yup
      .date()
      .nullable()
      .typeError(dictionary.required)
      .min(yup.ref('start_date'), dictionary.end_date_after_start)
  })

  const {
    control,
    setValue,
    formState: { errors },
    watch,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const operation = watch('operation')
  const watchLease = watch('lease')

  useEffect(() => {
    if (watchLease) {
      const selectedLease = leases.results?.find(l => l.id == watchLease)
      if (selectedLease.billing_option)
        setValue('billing_option', selectedLease.billing_option)
      if (selectedLease.tax_rate) setValue('tax_rate', selectedLease.tax_rate)
    }
  }, [leases, watchLease, setValue])

  const operationFilter =
    operation || operationId ? { operation: operationId ?? operation } : {}

  const { data: leases, isLoading: loadingLeases } = useFetchData(
    operation || operationId
      ? API_URL.LEASES.GET_LEASES_LIST() +
          '?' +
          queryString({
            // is_active: true,
            is_valid: true,
            page_size: 999999,
            ...operationFilter
          })
      : null
  )

  const selectedOperation = useMemo(() => {
    return operations?.results?.find(o => o.id == operation)
  }, [operations?.results, operation])

  useEffect(() => {
    setBank(selectedOperation?.bank)
  }, [selectedOperation])

  useEffect(() => {
    setValue('bank', bank)
  }, [bank])

  const fetchBankURL = operationDetails?.bank
    ? API_URL.BANKS.GET_BANK_ID(operationDetails.bank)
    : selectedOperation?.bank
    ? API_URL.BANKS.GET_BANK_ID(selectedOperation.bank)
    : null
  const { data: operationBank } = useFetchData(fetchBankURL)

  useEffect(() => {
    if (operationBank?.id) setValue('bank', operationBank.id)
  }, [operationBank, setValue])

  const banksURL = manage_banks ? API_URL.BANKS.GET_LIST() : null
  const { data: banksData } = useFetchData(banksURL)

  const { data: landlords, isLoading: loadingLandlords } = useFetchData(
    operation || operationId
      ? API_URL.LANDLORDS.GET_LANDLORDS_LIST() +
          '?' +
          queryString({ operations: operationFilter.operation })
      : null
  )

  const billingOptions = [
    { value: '30_days', title: dictionary['30_days'] },
    { value: '45_days', title: dictionary['45_days'] },
    { value: '60_days', title: dictionary['60_days'] },
    { value: '75_days', title: dictionary['75_days'] },
    { value: '90_days', title: dictionary['90_days'] },
    { value: 'end_of_month', title: dictionary['end_of_month'] },
    { value: 'on_reception', title: dictionary['on_reception'] },
    { value: 'due_on', title: t('invoicing_invoices_payment_terms_due_on') },
    { value: 'free', title: dictionary['free'] },
    { value: '5_days', title: dictionary['5_days'] },
    { value: '10_days', label: dictionary['10_days'] }
  ]

  const onSubmit = async values => {
    setLoading(true)
    const data = {
      operation: operationId ? operationId : values.operation,
      lease: leaseId ? leaseId : values.lease,
      type: 1,
      invoice_date: format(new Date(values.invoice_date), 'dd-MM-yyyy'),
      tax_rate: values.tax_rate,
      billing_option: values.billing_option,
      language: values.language,
      bank: values.bank,
      references: values.references,
      periods: values.periods,
      period_start_date: format(new Date(values.start_date), 'dd-MM-yyyy'),
      period_end_date: format(new Date(values.end_date), 'dd-MM-yyyy')
    }

    /* if (values.lease) {
      data.lease = values.lease
    } */

    if (values.landlord) {
      data.landlord = values.landlord
    }

    const response = await createInvoice(data)
    await responseHandler({
      response,
      callback: async () => {
        await mutate(invoicesURL)
        openInvoice && openInvoice(response.data.id)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_created,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
  }

  return (
    <>
      <Grid item xs={12}>
        <form data-cy={'form'} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {!operationId && (
              <Grid item xs={12}>
                <Select
                  fullWidth
                  name="operation"
                  label={dictionary.project}
                  variant="outlined"
                  control={control}
                  defaultValue={operationId ? operationId.toString() : ''}
                  error={Boolean(errors.operation)}
                  errorMessage={errors.operation && errors.operation.message}
                  isLoading={loadingOperations}
                  data_testid="Select-0a7f712a-e839-4c75-ad24-b466d366d028"
                >
                  {operations &&
                    operations.results.map(({ id, name }) => (
                      <MenuItem
                        key={id}
                        value={id}
                        data-testid="MenuItem-b66a40d0-6ea4-450e-9f15-911fb67b30a6"
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            )}

            {(operationId || operation) && (
              <>
                {!leaseId && (
                  <Grid item xs={12}>
                    {invoiceType === 'tenants' ? (
                      <Select
                        fullWidth
                        name="lease"
                        label={dictionary.lease}
                        variant="outlined"
                        control={control}
                        defaultValue=""
                        error={Boolean(errors.lease)}
                        errorMessage={errors.lease && errors.lease.message}
                        isLoading={loadingLeases}
                        data_testid="Select-d2dfa1b5-c6ea-473c-8675-c9a521663623"
                      >
                        {leases &&
                          leases.results.map(({ id, invoice_display }) => (
                            <MenuItem
                              key={id}
                              value={id}
                              data-testid="MenuItem-0e567b9c-800c-4901-845e-1527e7b7485d"
                            >
                              {invoice_display}
                            </MenuItem>
                          ))}
                      </Select>
                    ) : (
                      <Select
                        fullWidth
                        name="landlord"
                        label={dictionary.landlord}
                        variant="outlined"
                        control={control}
                        defaultValue=""
                        error={Boolean(errors.landlord)}
                        errorMessage={
                          errors.landlord && errors.landlord.message
                        }
                        isLoading={loadingLandlords}
                        data_testid="Select-7db12760-e356-44cd-bc2e-d54d05d65651"
                      >
                        {landlords &&
                          landlords.results.map(({ id, display }) => (
                            <MenuItem
                              key={id}
                              value={id}
                              data-testid="MenuItem-56759e6a-584e-44ee-97ee-5cd555c71d81"
                            >
                              {display}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DatePicker
                    name="invoice_date"
                    label={dictionary.invoice_date}
                    variant="outlined"
                    fullWidth
                    control={control}
                    defaultValue={new Date()}
                    error={Boolean(errors.invoice_date)}
                    errorMessage={
                      errors.invoice_date && errors.invoice_date.message
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="tax_rate"
                    defaultValue={20}
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          fullWidth
                          type="number"
                          variant="filled"
                          label={`${dictionary.tax_rate} (%)`}
                          error={Boolean(errors.tax_rate)}
                          helperText={errors?.tax_rate?.message}
                          sx={{
                            '& .MuiFilledInput-input': {
                              paddingTop: 2,
                              paddingBottom: 1
                            }
                          }}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name="billing_option"
                    label={dictionary.billing_option}
                    variant="outlined"
                    control={control}
                    defaultValue="on_reception"
                    error={Boolean(errors.billing_option)}
                    errorMessage={
                      errors.billing_option && errors.billing_option.message
                    }
                    sx={{
                      '& .MuiFilledInput-input': {
                        paddingTop: 2,
                        paddingBottom: 1
                      }
                    }}
                    data_testid="Select-5e27a30f-659d-4921-8c12-b056b7091abe"
                  >
                    {billingOptions.map(({ value, title }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        data-testid="MenuItem-b6c7110d-2917-4ebf-a39b-d7099bee7d1d"
                      >
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    name="language"
                    label={dictionary.language}
                    variant="outlined"
                    control={control}
                    defaultValue="fr"
                    error={Boolean(errors.language)}
                    errorMessage={errors.language && errors.language.message}
                    sx={{
                      '& .MuiFilledInput-input': {
                        paddingTop: 2,
                        paddingBottom: 1
                      }
                    }}
                    data_testid="Select-c845d320-5c8c-4824-9125-fd702780b3a2"
                  >
                    <MenuItem
                      value="en"
                      data-testid="MenuItem-ab30bfd9-61b5-4fab-a386-018c4fa79aad"
                    >
                      {dictionary['english']}
                    </MenuItem>
                    <MenuItem
                      value="fr"
                      data-testid="MenuItem-7c37a0df-93b7-4e1a-8787-aedf3b41ce0e"
                    >
                      {dictionary['french']}
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="bank"
                    defaultValue={operationBank?.id ? operationBank?.id : ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        fullWidth
                        variant="filled"
                        label={dictionary.bank}
                        error={Boolean(errors.bank) || !fetchBankURL}
                        errorMessage={errors.bank && errors.bank.message}
                        helperText={
                          !fetchBankURL ? dictionary.add_billing_bank_desc : ''
                        }
                        disabled={!manage_banks}
                        sx={{
                          '& .MuiFilledInput-input': {
                            paddingTop: 2,
                            paddingBottom: 1
                          }
                        }}
                      >
                        <MenuItem
                          value={null}
                          onClick={() => setIsAddBank(true)}
                        >
                          <Add /> {dictionary.add_bank}
                        </MenuItem>
                        {banksData &&
                          banksData?.results?.map(bank => (
                            <MenuItem key={bank?.id} value={bank?.id}>
                              {bank?.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="references"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="filled"
                        label={dictionary.references}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateRangePickerCustom
                    setValue={setValue}
                    name="date_range"
                    defaultValue={[null, null]}
                    start_date={''}
                    end_date={''}
                    variant="outlined"
                    fullWidth
                    control={control}
                    required
                    errors={errors}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} align="right">
              <LoadingButton
                type="submit"
                loading={loading}
                data-testid="Button-52a0e53e-e8ad-4a52-ae3e-b516178c85a6"
              >
                {dictionary.save}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <CommonModal
        open={isAddBank}
        title={dictionary.bank}
        onClose={() => setIsAddBank(false)}
        maxWidth={'sm'}
      >
        <AccountSettingsBanksForm
          banksURL={banksURL}
          handleClose={() => setIsAddBank(false)}
          setValue={setValue}
        />
      </CommonModal>
    </>
  )
}
