export const formatNumber = ({
  number = 0,
  precision = 0,
  unit = '',
  adaptablePrecision = false,
  unitOnLeft = false
}) => {
  const parsedUnit = unit
    ? unit === '%'
      ? unit
      : unitOnLeft
      ? `${unit}\u00A0`
      : `\u00A0${unit}`
    : ''
  let n = number
  let newPrecision = 0
  if (adaptablePrecision && Number(n) < 0.1) {
    newPrecision += 2
    if (Number(n) < 0.01) {
      newPrecision++
      if (Number(n) < 0.001) {
        newPrecision = 0
      }
    }
  }
  if (
    precision === 0 &&
    Number(n) > 0 &&
    Number(n) < 1 &&
    !adaptablePrecision
  ) {
    precision = 2
  }
  let newNumber = Number(number?.toString().replace(',', '.'))
  if (isNaN(newNumber)) {
    return Number(0).toFixed(precision)
  }
  n = Number.parseFloat(newNumber).toFixed(newPrecision || precision)
  // const parsedNumber = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')
  const numberString = n.toString()
  let formattedNumber = ''
  let count = 0
  for (let i = numberString.length - 1; i >= 0; i--) {
    if (numberString[i] === '.') {
      formattedNumber = '.' + formattedNumber
      count = 0 // Reset the count after encountering a decimal point
    } else {
      formattedNumber = numberString[i] + formattedNumber
      count++
      if (count % 3 === 0 && i !== 0) {
        formattedNumber = '\u00A0' + formattedNumber
      }
    }
  }
  const parsedNumber = formattedNumber

  const parsedNumberWithUnit = unitOnLeft
    ? parsedUnit + parsedNumber
    : parsedNumber + parsedUnit
  return parsedNumberWithUnit
}
