import FilterActionsSelector from '@components/common/Filter/ActionsSelector'
import FiltersElement from '@components/common/Filter/FiltersElement'
import CommonModal from '@components/common/Modal/CommonModal'
import CustomSearchInput from '@components/common/SearchInput'
import routes from '@config/routes'
import { useAuth } from '@contexts/auth'
import { useTranslation } from '@contexts/translation'
import {
  Add,
  AutoFixNormal,
  FileDownload,
  FileUpload,
  Inventory2,
  PictureAsPdf
} from '@mui/icons-material'
import { Button, Stack, Tooltip } from '@mui/material'
import getTotal from '@utils/getTotal'
import { useCallback, useEffect, useMemo, useState } from 'react'
import AddInvoiceForm from './AddInvoiceForm'
import InvoiceTemplates from './InvoiceTemplates/InvoiceTemplates'
import CreateCreditNote from './CreateCreditNote'
import downloadXLS from '@utils/downloadXLS'
import downloadPDF from '@utils/downloadPDF'
import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'

const InvoicesHeader = ({
  dataProps = {},
  actionsProps = {},
  queriesProps = {},
  params = {}
}) => {
  const { invoicesList, invoicesURL, invoicesCountsURL } = dataProps
  const { actionsModal, dispatchActionsModal } = actionsProps
  const { dispatchInvoicesQueries, filterQueries, dispatchFilterQueries } =
    queriesProps
  const { operationId, leaseId, invoicesType, setIsGenerated } = params
  const { profilePreferences, teamSettings } = useAuth()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const onClose = useCallback(() => {
    dispatchActionsModal('close')
  }, [dispatchActionsModal])

  const currentURL = window.location.href

  const canAddInvoice = currentURL.includes('validation') ? false : true

  const canLaunchSuggestions = currentURL.includes('validation') ? false : true

  const operationURL = operationId
    ? API_URL.PROJECTS.GET_PROJECT_PK(operationId)
    : null

  const { data: projectData } = useFetchData(operationURL)

  const [isStyleGuide, setIsStyleGuide] = useState(false)
  useEffect(() => {
    setIsStyleGuide(projectData?.default_styleguide === null ? false : true)
  }, [projectData])

  const downloadData = useMemo(() => {
    if (invoicesList?.results) {
      const totalBT = profilePreferences?.show_before_tax
        ? t('total_bt')
        : t('total_at')
      const data =
        invoicesList?.results?.map(invoice => {
          return {
            [t('invoices')]: invoice.code,
            [t('type')]: invoice.type_display,
            [t('operation')]: invoice.operation.name,
            [t('invoice_date')]: invoice.invoice_date,
            [t('due_date')]: invoice.due_date,
            [t('currency')]: invoice.currency?.symbol || '',
            [totalBT]: profilePreferences?.show_before_tax
              ? invoice.subtotal.toFixed(2)
              : invoice.amount.toFixed(2),
            [t('status')]: invoice.status_display
          }
        }) || []
      return [
        ...data,
        {
          [t('invoices')]: '',
          [t('type')]: '',
          [t('operation')]: '',
          [t('invoice_date')]: '',
          [t('due_date')]: '',
          [t('currency')]: '',
          [totalBT]: profilePreferences?.show_before_tax
            ? getTotal(invoicesList?.results, 'subtotal')?.toFixed(2)
            : getTotal(invoicesList?.results, 'amount')?.toFixed(2),
          [t('status')]: ''
        }
      ]
    }
  })

  const options = !currentURL.includes('validation')
    ? [
        { label: t('actions') },
        {
          icon: <FileUpload color="secondary" />,
          label: (
            <a
              href={
                operationId
                  ? `${routes.project.settings.import}/${operationId}`
                  : routes.account.data.imports
              }
              rel="noreferrer"
            >
              {t('import')}
            </a>
          )
        },
        {
          icon: <FileDownload color="secondary" />,
          label: t('export_xls'),
          disabled: !downloadData?.length,
          handleClick: () => downloadXLS(downloadData, t('invoices'))
        },
        {
          icon: <PictureAsPdf color="secondary" />,
          label: t('export_pdf'),
          disabled: !downloadData?.length,
          handleClick: () =>
            downloadPDF(downloadData, t('invoices'), teamSettings?.name)
        },
        {
          icon: <Inventory2 color="primary" />,
          label: t('invoicing_invoices_create_credit_note'),
          handleClick: () => dispatchActionsModal({ type: 'creditNote' })
        }
      ]
    : [
        { label: t('actions') },
        {
          icon: <FileDownload color="secondary" />,
          label: t('export_xls'),
          disabled: !downloadData?.length,
          handleClick: () => downloadXLS(downloadData, t('invoices'))
        },
        {
          icon: <PictureAsPdf color="secondary" />,
          label: t('export_pdf'),
          disabled: !downloadData?.length,
          handleClick: () =>
            downloadPDF(downloadData, t('invoices'), teamSettings?.name)
        }
      ]

  return (
    <Stack
      direction={isMediumUp ? 'row' : 'column'}
      alignItems={isMediumUp ? 'center' : 'flex-start'}
      spacing={2}
      paddingTop={isMediumUp ? 0 : 2}
    >
      <CustomSearchInput
        handleChange={e => dispatchInvoicesQueries({ q: e.target.value })}
      />

      <FiltersElement
        handleQueries={dispatchFilterQueries}
        defaultQueries={{}}
        resetPage={() => dispatchInvoicesQueries({ page: 1 })}
        operation={!operationId ? filterQueries?.operation || '' : null}
        invoice_payment_type={filterQueries?.payment_type || ''}
        lease={
          !leaseId && invoicesType === 'tenants'
            ? filterQueries?.lease || ''
            : null
        }
        tenant={invoicesType === 'tenants' ? filterQueries?.tenant || '' : null}
        landlord={
          invoicesType === 'landlords' ? filterQueries?.landlord || '' : null
        }
        invoice_status={leaseId && (filterQueries?.status || '')}
        date
      />

      {canAddInvoice && (
        <Tooltip
          title={
            !isStyleGuide
              ? t('project_invoicing_invoices_define_styleguide')
              : null
          }
        >
          <span>
            <Button
              startIcon={<Add />}
              data-cy={'add-btn'}
              onClick={() => dispatchActionsModal({ type: 'add' })}
              data-testid="Button-fbeb5371-c68c-4b7d-b3c4-dc2ffde65c69"
              disabled={!isStyleGuide}
            >
              {t('invoice')}
            </Button>
          </span>
        </Tooltip>
      )}

      {canLaunchSuggestions && (
        <Tooltip
          title={
            !isStyleGuide
              ? t('project_invoicing_invoices_define_styleguide')
              : null
          }
        >
          <span>
            <Button
              onClick={() => dispatchActionsModal({ type: 'suggestions' })}
              startIcon={<AutoFixNormal />}
              disabled={!isStyleGuide}
              data-testid="Button-e26328bf-ad7c-4170-a313-f9ec0c85db61"
            >
              {t('suggestions')}
            </Button>
          </span>
        </Tooltip>
      )}

      <FilterActionsSelector isMenu options={options} />

      <CommonModal
        title={t('invoice')}
        open={actionsModal?.add}
        onClose={onClose}
        maxWidth="xs"
      >
        <AddInvoiceForm
          operationId={operationId}
          invoicesURL={invoicesURL}
          openInvoice={id =>
            dispatchActionsModal({ type: 'details', invoiceId: id })
          }
          invoiceType={invoicesType}
          leaseId={leaseId}
        />
      </CommonModal>

      <CommonModal
        title={t('suggestions')}
        open={actionsModal?.suggestions}
        onClose={onClose}
        maxWidth="md"
      >
        <InvoiceTemplates
          operationId={operationId}
          fetchURL={invoicesURL}
          invoicesCountsURL={invoicesCountsURL}
          invoicesList={invoicesList}
          setIsGenerated={setIsGenerated}
          onClose={onClose}
          //   currency={currency}
        />
      </CommonModal>

      <CommonModal
        title={t('invoicing_invoices_create_credit_note')}
        open={actionsModal?.creditNote}
        onClose={onClose}
        maxWidth="xs"
      >
        <CreateCreditNote
          openInvoice={id =>
            dispatchActionsModal({ type: 'details', invoiceId: id })
          }
          invoicesCountsURL={invoicesCountsURL}
          leaseId={leaseId}
          operationId={operationId}
          onClose={onClose}
        />
      </CommonModal>
    </Stack>
  )
}

export default InvoicesHeader
