import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useTranslation } from '@contexts/translation'
import {
  Alert,
  Grid,
  Button,
  MenuItem,
  Typography,
  ListItemIcon
} from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

// import { useAuth } from '@contexts/auth'
import TextFieldItem from '@components/common/Form/TextFieldItem'
import Select from '@components/common/Select'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import { mutate } from 'swr'
import { createBank, patchBank } from '@api/banks'
import Flag from 'react-world-flags'
import { responseHandler } from '@utils/responseHandler'
import { useAuth } from '@contexts/auth'

const AccountSettingsBanksForm = ({
  isEdit,
  handleClose,
  bank,
  // projects = null,
  banksURL,
  // project = null,
  setValue = null
}) => {
  // const { organization } = useAuth()
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const { orgSettings } = useAuth()

  // const { data: fetchTeams } = useFetchData(
  //   API_URL.TEAMS.GET_TEAMS_LIST(organization)
  // )
  const { data: fetchCurrencies } = useFetchData(
    API_URL.CURRENCIES.GET_CURRENCIES_LIST
  )

  const { data: fetchCountries } = useFetchData(API_URL.COUNTRIES.GET_COUNTRIES)

  const schema = yup.object().shape({
    name: yup.string().required(dictionary.required),
    description: yup.string(),
    currency: yup.string(),
    country: yup.string(),
    iban: yup.string(),
    bic: yup.string(),
    rib: yup.string(),
    address: yup.string(),
    postal_code: yup.string(),
    city: yup.string()
    // teams: yup.array(),
    // projects: yup.array()
  })

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: isEdit
      ? {
          name: bank.name,
          description: bank.description,
          currency: bank.currency || '',
          country: bank.country || '',
          iban: bank.iban,
          bic: bank.bic,
          rib: bank.rib,
          address: bank.address,
          postal_code: bank.postal_code,
          city: bank.city
          // teams: bank.teams?.map(x => x),
          // projects: bank.projects?.map(x => x)
        }
      : {
          name: '',
          description: '',
          currency: orgSettings.currency,
          country: orgSettings.country,
          iban: '',
          bic: '',
          rib: '',
          address: '',
          postal_code: '',
          city: ''
          // teams: project ? [project.team] : [],
          // projects: project ? [project.id] : []
        }
  })

  const onSubmit = async values => {
    setLoading(true)
    let response = null
    if (isEdit) response = await patchBank(bank.id, values)
    else response = await createBank(values)

    responseHandler({
      response,
      callback: async () => {
        await mutate(banksURL)
        setValue && setValue('bank', response.data.id)
      },
      dictionary,
      msgSuccess: isEdit ? dictionary.bank_updated : dictionary.bank_created,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    handleClose && handleClose()
  }

  return (
    <form
      data-cy={'form'}
      style={{ width: '100%' }}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      data-testid="accountSettingsBanksForm"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Alert
            severity="info"
            variant="filled"
            sx={{ backgroundColor: t => t.palette.secondary.main }}
          >
            {dictionary.info_banks}
          </Alert>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            data-testid="Typography-cf5fb00a-e6c0-4523-ac4d-4cf2f08eebbe"
          >
            {dictionary.general_information}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Name */}
          <Controller
            name="name"
            control={control}
            render={props => (
              <TextFieldItem
                required
                placeholder={dictionary.name}
                label={dictionary.name}
                errorMessage={errors.name?.message}
                {...props}
                data_testid="TextFieldItem-0c95cc58-be4a-4d74-911f-9eb48961dee6"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Description */}
          <Controller
            name="description"
            control={control}
            render={props => (
              <TextFieldItem
                placeholder={dictionary.description}
                label={dictionary.description}
                errorMessage={errors.description?.message}
                {...props}
                data_testid="TextFieldItem-82b30c0b-85a2-49c1-984f-e709ca34c069"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Currency */}
          <Select
            fullWidth
            name="currency"
            label={dictionary.currency}
            variant="outlined"
            control={control}
            defaultValue={''}
            data_testid="Select-bd6fff4c-5acf-4757-9e59-40be0c89470c"
          >
            <MenuItem
              key={''}
              value={''}
              data-testid="MenuItem-890f9808-44a4-4aa5-890d-609b0b2ef8f4"
            >
              {'----------'}
            </MenuItem>
            {fetchCurrencies &&
              fetchCurrencies.results.map(({ id, display }) => (
                <MenuItem
                  key={display}
                  value={id}
                  data-testid="MenuItem-a6b4bf2e-1acb-455d-8933-05af07b6fe30"
                >
                  {display}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Address */}
          <Controller
            name="address"
            control={control}
            render={props => (
              <TextFieldItem
                placeholder={dictionary.address}
                label={dictionary.address}
                errorMessage={errors.address?.message}
                {...props}
                data_testid="TextFieldItem-50bea241-32ab-4d9c-b407-41da5c3de1a5"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Postal Code */}
          <Controller
            name="postal_code"
            control={control}
            render={props => (
              <TextFieldItem
                placeholder={dictionary.postal_code}
                label={dictionary.postal_code}
                errorMessage={errors.postal_code?.message}
                {...props}
                data_testid="TextFieldItem-4dff1cfb-b813-4829-8257-94bedbed1c67"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* City */}
          <Controller
            name="city"
            control={control}
            render={props => (
              <TextFieldItem
                placeholder={dictionary.city}
                label={dictionary.city}
                errorMessage={errors.city?.message}
                {...props}
                data_testid="TextFieldItem-f76318f1-7b24-40ef-8fc4-f55b3651627c"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Country */}
          <Select
            fullWidth
            name="country"
            label={dictionary.country}
            variant="outlined"
            control={control}
            defaultValue={''}
            data_testid="Select-4cf0fa49-1f91-45ff-bf90-a543a712366b"
          >
            <MenuItem
              key={''}
              value={''}
              data-testid="MenuItem-0d80089d-7069-4c2f-9dba-b32bad192606"
            >
              {'----------'}
            </MenuItem>
            {fetchCountries &&
              fetchCountries.countries.map(ct => (
                <MenuItem
                  key={ct[0]}
                  value={ct[0]}
                  data-testid="MenuItem-8c3261e0-ec29-486f-9f70-48193968f90b"
                >
                  <ListItemIcon>
                    <Flag code={ct[0]} height="14" />
                  </ListItemIcon>
                  {ct[1]}
                </MenuItem>
              ))}
          </Select>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            data-testid="Typography-fef222d8-4255-405d-854f-10092afd3282"
          >
            {dictionary.banking_information}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* IBAN */}
          <Controller
            name="iban"
            control={control}
            render={props => (
              <TextFieldItem
                placeholder={dictionary.iban}
                label={dictionary.iban}
                errorMessage={errors.iban?.message}
                {...props}
                data_testid="TextFieldItem-0a21f80b-86ee-4b49-af73-eef3cc9f4f17"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* BIC */}
          <Controller
            name="bic"
            control={control}
            render={props => (
              <TextFieldItem
                placeholder={dictionary.bic}
                label={dictionary.bic}
                errorMessage={errors.bic?.message}
                {...props}
                data_testid="TextFieldItem-f93af7f9-095f-4988-8aca-9fb7bd714cf2"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* RIB */}
          <Controller
            name="rib"
            control={control}
            render={props => (
              <TextFieldItem
                placeholder={dictionary.rib}
                label={dictionary.rib}
                errorMessage={errors.rib?.message}
                {...props}
                data_testid="TextFieldItem-24c25287-f188-4243-a01f-024ec15788f0"
              />
            )}
          />
        </Grid>
        {/* Projects */}
        {/*<Select
                     multiple
                     fullWidth
                     name="projects"
                     label={dictionary.projects}
                     variant="outlined"
                     control={control}
                     defaultValue={[]}
                   >
                     {projects &&
                       projects.results.map(({ id, project_title }) => (
                         <MenuItem key={Math.random() + id} value={id}>
                           {project_title}
                         </MenuItem>
                       ))}
                   </Select>
                 </Grid>
               </>
                       )*/}
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            data-testid="Button-96d6ee04-891a-42ff-b741-144b36c11831"
          >
            {dictionary.save}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default AccountSettingsBanksForm
