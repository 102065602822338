import React, { useState, useMemo, useCallback, useEffect } from 'react'
import {
  Grid,
  Chip,
  Stack,
  Tooltip,
  Box,
  Typography,
  Badge
} from '@mui/material'
import {
  Delete,
  Mail,
  Inventory2,
  Payment,
  Send,
  ScheduleSend,
  Close
} from '@mui/icons-material'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import ActionsPopover from '@components/common/ActionsPopover'
import DetailModal from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal'
import { useAuth } from '@contexts/auth'
import { useTranslation } from '@contexts/translation'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import {
  onDeleteInvoice,
  postInvoicesAction,
  updateInvoice
} from '@api/invoices'

import useChipStyles from '@styles/chip/chip'

import DeleteModal from '@components/common/DeleteModal'
import { useRouter } from 'next/router'
import { responseHandler } from '@utils/responseHandler'
import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import PlaceHolder from '@components/common/PlaceHolder'
import TableTemplate from '@components/common/Table'
import { differenceInDays } from 'date-fns'
import unformatDate from '@utils/unformatDate'
import { formatNumber } from '@utils/formatNumber'
import getTotal from '@utils/getTotal'
import FilterActionsSelectorChip from '@components/common/Filter/ActionsSelectorChip'
import InvoicesHeader from './InvoicesHeader'
import FilterActionsSelector from '@components/common/Filter/ActionsSelector'
import CommonModal from '@components/common/Modal/CommonModal'
import ActionModal from './ActionModal'

export const actionsModalReducerInitialState = {
  add: false,
  delete: false,
  cancel: false,
  markAsSent: false,
  addPayment: false,
  suggestions: false,
  creditNote: false,
  details: false,
  detailsTab: 0,
  invoice: null,
  invoiceId: null
}
export const actionsModalReducer = (state, action) => {
  if (action === 'close') return actionsModalReducerInitialState
  else if (action.type)
    return {
      ...actionsModalReducerInitialState,
      [action.type]: true,
      invoice: action.invoice,
      invoiceId: action.invoiceId,
      detailsTab: action.detailsTab
    }
  return state
}

// invoice types:
export const INVOICE_TYPES = {
  production: 1,
  expenses: 2,
  ffne: 3,
  other: 4,
  advance: 5,
  project_insource: 6,
  hours: 7,
  insource: 8,
  credit_note: 9,
  imported: 10,
  mandate: 12
}

const InvoicesInfo = ({
  view,
  displayInvoicesHeader = true,
  dataProps = {},
  queriesProps = {},
  actionsProps = {},
  params = {},
  selectionProps = {}
}) => {
  const { invoicesList, isLoadingInvoices, invoicesURL, invoicesCountsURL } =
    dataProps
  const {
    invoicesQueries,
    dispatchInvoicesQueries,
    filterQueries,
    dispatchFilterQueries
  } = queriesProps
  const { actionsModal, dispatchActionsModal, setInvoicesType } = actionsProps
  const { invoicesType, operationId, leaseId, setIsGenerated } = params
  const { itemsSelected, setItemsSelected } = selectionProps

  const classesChip = useChipStyles()
  const theme = useTheme()
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))
  const router = useRouter()
  const currentURL = router.asPath

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { membership, orgSettings, operationDetails, organization } = useAuth()

  const [isWarning, setIsWarning] = useState({
    open: false,
    msg: '',
    payload: null
  })
  const [actionModal, setActionModal] = useState({
    open: false,
    title: '',
    payload: null
  })

  const currencyDisplay =
    operationDetails?.currency_display || orgSettings?.currency_symbol || ''

  // const [isAddPayment, setIsAddPayment] = useState(false)
  const [loading, setLoading] = useState(false)

  const { data: banksList, isLoading: isLoadingBanks } = useFetchData(
    API_URL.BANKS.GET_LIST()
  )

  useEffect(() => {
    if (
      !actionsModal?.details &&
      invoicesList?.results &&
      router.query.invoice
    ) {
      dispatchActionsModal({ type: 'details', invoiceId: router.query.invoice })
    }
  }, [invoicesList, router, actionsModal?.details])

  const getChipColor = status => {
    if (status === 'late') return classesChip.error
    if (status === 'not_paid' || status === 'partial')
      return classesChip.warning
    if (status === 'paid') return classesChip.success
    if (status === 'not_sent') return classesChip.secondary
  }

  const onClose = useCallback(() => {
    if (router.query.invoice) {
      delete router.query.invoice
    }
    dispatchActionsModal('close')
    setActionModal({ open: false, title: '', payload: null })
    setIsWarning({ open: false, msg: '', payload: null })
  }, [dispatchActionsModal, router])

  const handleDeleteInvoice = async () => {
    if (actionsModal.invoiceId) {
      setLoading(true)
      const response = await onDeleteInvoice(actionsModal.invoiceId)
      await responseHandler({
        response,
        callback: async () => {
          await mutate(invoicesURL)
          if (invoicesCountsURL) await mutate(invoicesCountsURL)
        },
        t,
        msgSuccess: t('response_message_invoice_deleted'),
        snackbar: enqueueSnackbar
      })
      setLoading(false)
      onClose()
    }
  }

  const handleUpdateInvoice = async () => {
    if (actionsModal.invoiceId) {
      setLoading(true)
      const payload = actionsModal.markAsSent
        ? { is_sent: true }
        : actionsModal.cancel
        ? { is_closed: true }
        : {}
      const response = await updateInvoice(actionsModal.invoiceId, payload)
      await responseHandler({
        response,
        callback: async () => {
          await mutate(invoicesURL)
          if (invoicesCountsURL) await mutate(invoicesCountsURL)

          if (actionsModal.cancel && response?.data.credit_note_url) {
            const creditNoteURL =
              response?.data.credit_note_url.split('/') || []
            const creditNoteId = creditNoteURL[creditNoteURL?.length - 2]
            dispatchActionsModal({ type: 'details', invoiceId: creditNoteId })
          }
        },
        t,
        msgSuccess: t('response_message_invoice_updated'),
        snackbar: enqueueSnackbar
      })
      setLoading(false)
      onClose()
    }
  }

  useEffect(() => {
    setItemsSelected([])
  }, [view])

  const multiSelectOptions = useMemo(() => {
    return [
      { label: '', handleClick: () => null },
      {
        label: t('invoicing_invoices_mark_as_validated'),
        handleClick: () => {
          setIsWarning({
            open: true,
            msg: t('invoicing_invoices_mark_as_validated'),
            payload: {
              action: 'validate',
              pks: itemsSelected
            }
          })
        },
        hidden: view !== 'draft'
      },
      {
        label: t('invoicing_invoices_mark_as_sent'),
        handleClick: () => {
          setIsWarning({
            open: true,
            msg: t('invoicing_invoices_mark_as_sent'),
            payload: {
              action: 'send',
              pks: itemsSelected
            }
          })
        },
        hidden: view !== 'to-send'
      },
      {
        label: t('invoicing_invoices_plan_email'),
        handleClick: () => {
          setActionModal({
            open: true,
            title: t('invoicing_invoices_plan_email'),
            payload: {
              action: 'schedule',
              pks: itemsSelected
            }
          })
        },
        hidden: view !== 'to-send'
      },
      {
        label: t('invoicing_invoices_mark_as_paid'),
        handleClick: () => {
          setActionModal({
            open: true,
            title: t('invoicing_invoices_mark_as_paid'),
            payload: {
              action: 'paid',
              invoices: itemsSelected
            }
          })
        },
        hidden: view !== 'open'
      }
    ]
  }, [t, itemsSelected, view])

  const multiActions = async payload => {
    setLoading(true)
    const response = await postInvoicesAction(organization, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(invoicesURL)
        await mutate(invoicesCountsURL)
      },
      t,
      msgSuccess: t('invoicing_invoices_updated'),
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    onClose()
  }

  const actionsRows = useCallback(
    invoice => {
      const options = []
      if (invoice.status === 'not_valid' || invoice.status === 'autodraft')
        options.push({
          id: 1,
          label: t('delete'),
          icon: <Delete color="error" />,
          handleClick: () =>
            dispatchActionsModal({ type: 'delete', invoiceId: invoice.id })
        })
      else {
        if (invoice.status === 'not_sent') {
          if (!invoice.is_scheduled) {
            options.push(
              {
                id: 2,
                label: t('send'),
                icon: <Send color="secondary" />,
                handleClick: () =>
                  dispatchActionsModal({
                    type: 'details',
                    invoiceId: invoice.id,
                    detailsTab: 5
                  })
              },
              {
                id: 3,
                label: t('invoicing_invoices_schedule'),
                icon: <ScheduleSend color="secondary" />,
                handleClick: () =>
                  dispatchActionsModal({
                    type: 'details',
                    invoiceId: invoice.id,
                    detailsTab: 5
                  })
              }
            )
          }
          options.push({
            id: 4,
            label: t('invoicing_invoices_mark_as_sent'),
            icon: <Mail color="secondary" />,
            handleClick: () =>
              dispatchActionsModal({
                type: 'markAsSent',
                invoiceId: invoice.id
              })
          })
        }
        if (
          invoice.status === 'late' ||
          invoice.status === 'partial' ||
          invoice.status === 'not_paid' ||
          invoice.status === 'litigious'
        ) {
          options.push({
            id: 5,
            label: t('pay'),
            icon: <Payment color="secondary" />,
            handleClick: () => {
              dispatchActionsModal({
                type: 'details',
                invoiceId: invoice.id,
                detailsTab: 6
              })
            }
          })
        }
        options.push({
          id: 5,
          label:
            invoice.type === INVOICE_TYPES.credit_note
              ? t('cancel')
              : t('invoicing_invoices_create_credit_note'),
          icon:
            invoice.type === INVOICE_TYPES.credit_note ? (
              <Close color="error" />
            ) : (
              <Inventory2 color="primary" />
            ),
          handleClick: () =>
            dispatchActionsModal({ type: 'cancel', invoiceId: invoice.id })
        })
      }
      return options
    },
    [dispatchActionsModal, t]
  )

  const columns = useMemo(() => {
    return [
      { field: 'code', title: t('code') },
      { field: 'project', title: t('operation'), hidden: Boolean(operationId) },
      {
        field: 'tenant',
        title: t('tenant'),
        hidden: invoicesType === undefined || invoicesType === 'landlords'
      },
      {
        field: 'landlord',
        title: t('landlord'),
        hidden: invoicesType === undefined || invoicesType === 'tenants'
      },
      { field: 'invoice_date', title: t('invoice_date') },
      { field: 'due_date', title: t('due_date') },
      { field: 'subtotal', title: t('subtotal'), align: 'right' },
      { field: 'total', title: t('total'), align: 'right' },
      { field: 'balance', title: t('balance'), align: 'right' },
      {
        field: 'status',
        title:
          view === 'draft'
            ? t('status') + '-' + t('invoicing_invoices_approval')
            : t('status')
      },
      {
        field: 'actions',
        title: '',
        width: 0,
        sorting: false,
        hidden: Boolean(membership?.is_readonly)
      }
    ]
  }, [t, membership?.is_readonly, invoicesType, operationId, view])

  const bodyData = useMemo(() => {
    if (invoicesList?.results) {
      return invoicesList.results.map(invoice => {
        const pastDueDays = differenceInDays(
          new Date(),
          unformatDate(invoice.due_date)
        )
        const invoiceIsLate = invoice.is_past_due && pastDueDays > 0

        const invoiceIsSuggested = invoice.status === 'not_valid_auto'
        const chipStatusText =
          invoice.is_scheduled && !invoice.is_sent
            ? t('invoicing_invoices_status_scheduled', {
                date: invoice.send_on_date
              })
            : invoice.status_display

        const displayActions =
          Boolean(dispatchActionsModal) &&
          invoice.status !== 'canceled' &&
          invoice.status !== 'paid' &&
          invoice.status !== 'closed'

        const approvalsEnabled = invoice.operation.invoice_approval_enabled
        const approvalsNeeded = invoice.operation.invoice_approvals_count
        const approvalsCount = invoice.approvals_count

        return {
          id: invoice.id,
          hover: true,
          code: invoice.code,
          project: invoice.operation?.name,
          tenant: invoice.tenant?.display,
          landlord: invoice.landlord?.display,
          invoice_date: invoice.invoice_date,
          due_date: (
            <Stack direction="row" spacing={1} alignItems="center">
              <span>{invoice.due_date}</span>
              {invoiceIsLate && (
                <Tooltip
                  title={t('invoicing_invoices_due_date_tooltip', {
                    dateSent: invoice.invoice_date,
                    billingOption: invoice.billing_option_display,
                    count: pastDueDays
                  })}
                >
                  <Typography variant="caption" color="error">
                    {`+${pastDueDays}`}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
          ),
          subtotal: formatNumber({
            number: invoice.subtotal,
            precision: 2,
            unit: invoice?.currency?.symbol
          }),
          total: formatNumber({
            number: invoice.amount,
            precision: 2,
            unit: invoice?.currency?.symbol
          }),
          balance: formatNumber({
            number: invoice.balance,
            precision: 2,
            unit: invoice?.currency?.symbol
          }),
          status: (
            <Stack direction="row" spacing={1}>
              <Tooltip
                title={
                  invoiceIsSuggested
                    ? t('invoicing_invoices_suggestion_tooltip')
                    : ''
                }
              >
                <Chip
                  size="small"
                  className={getChipColor(invoice.status)}
                  label={chipStatusText}
                  data-testid="Chip-4145c0b6-3a66-4955-8bd5-6f3970389c89"
                />
              </Tooltip>

              {approvalsEnabled && approvalsNeeded > 0 && view === 'draft' && (
                <Chip
                  size="small"
                  className={
                    approvalsCount === approvalsNeeded
                      ? classesChip.primaryLight
                      : ''
                  }
                  label={`${approvalsCount}/${approvalsNeeded}`}
                  data-testid="Chip-370177c2-824c-483f-a0ba-3835435f2e39"
                />
              )}
            </Stack>
          ),
          actions: displayActions ? (
            <ActionsPopover actions={actionsRows(invoice)} />
          ) : (
            ''
          )
        }
      })
    }
  }, [invoicesList?.results, t, actionsRows])

  const footerData = useMemo(() => {
    if (invoicesList?.results) {
      return [
        {
          id: 'total',
          code: t('total'),
          subtotal: formatNumber({
            number: getTotal(invoicesList?.results, 'subtotal'),
            precision: 2,
            unit: currencyDisplay || ''
          }),
          total: formatNumber({
            number: getTotal(invoicesList?.results, 'amount'),
            precision: 2,
            unit: currencyDisplay || ''
          }),
          balance: formatNumber({
            number: getTotal(invoicesList?.results, 'balance'),
            precision: 2,
            unit: currencyDisplay || ''
          })
        }
      ]
    }
  }, [t, invoicesList?.results])

  const actionsModalProps = useMemo(() => {
    const description = actionsModal?.delete
      ? t('confirm_delete_invoice')
      : actionsModal?.markAsSent
      ? t('confirm_mark_as_sent_invoice')
      : actionsModal?.cancel
      ? t('confirm_close_invoice')
      : undefined

    const onConfirm = actionsModal?.delete
      ? handleDeleteInvoice
      : handleUpdateInvoice
    return {
      open: Boolean(description),
      description,
      onClose,
      loading,
      onConfirm
    }
  }, [actionsModal, t, onClose, loading])

  if (banksList && banksList?.results?.length < 1) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              height: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div>
              <PlaceHolder
                src="/Placeholders/Keysy/Invoice.png"
                width={250}
                height={250}
              />
              <Typography data-testid="Typography-ecdbed96-36bc-4bd4-bfc3-def8dfee9c85">
                {t('invoicing_invoices_add_bank')}
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection={isMediumUp ? 'row' : 'column'}
        justifyContent={
          setInvoicesType || itemsSelected?.length > 0
            ? 'space-between'
            : 'flex-end'
        }
        alignItems={isMediumUp ? 'center' : 'flex-start'}
      >
        <Stack direction="row" spacing={2}>
          {setInvoicesType && (
            <FilterActionsSelectorChip
              options={[
                {
                  label: t('tenants'),
                  handleClick: () => {
                    setInvoicesType('tenants')
                  }
                },
                {
                  label: t('landlords'),
                  handleClick: () => {
                    setInvoicesType('landlords')
                  }
                }
              ]}
            />
          )}

          {itemsSelected?.length > 0 && (
            <Badge badgeContent={itemsSelected.length || 0} color="warning">
              <FilterActionsSelector
                isMenu
                label={t('actions')}
                options={multiSelectOptions}
                loadingAction={loading}
                variant={'primary'}
              />
            </Badge>
          )}
        </Stack>

        {displayInvoicesHeader && (
          <InvoicesHeader
            dataProps={{
              invoicesList,
              isLoadingInvoices,
              invoicesURL,
              invoicesCountsURL
            }}
            queriesProps={{
              dispatchInvoicesQueries,
              filterQueries,
              dispatchFilterQueries
            }}
            actionsProps={{ actionsModal, dispatchActionsModal }}
            params={{ invoicesType, operationId, leaseId, setIsGenerated }}
            selectionProps={{ itemsSelected, setItemsSelected }}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <TableTemplate
          headerProps={{ columns }}
          bodyProps={{
            data: bodyData,
            isLoading: isLoadingInvoices || isLoadingBanks || !bodyData,
            onRowClick: (e, row) => {
              router.push({
                pathname: router.pathname,
                query: { id: operationId, invoice: row.id, view: view }
              })
              dispatchActionsModal({
                type: 'details',
                invoiceId: row.id
              })
            }
          }}
          footerProps={{ data: footerData }}
          paginationProps={{
            enabled: !currentURL.includes('invoicing/planning') && true,
            count: invoicesList?.count,
            page: +invoicesQueries?.page - 1,
            rowsPerPage: +invoicesQueries?.page_size,
            dispatchQueries: dispatchInvoicesQueries
          }}
          sortProps={{ enabled: true }}
          selectionProps={{
            enabled:
              Boolean(setItemsSelected) &&
              (view === 'draft' || view === 'to-send' || view === 'open'),
            onSelectionChange: selection => setItemsSelected(selection)
          }}
          placeholderProps={{
            src: '/Placeholders/Keysy/Invoice.png',
            text:
              view === 'draft'
                ? t('invoicing_invoices_no_draft')
                : t('invoicing_invoices_none')
          }}
        />
      </Grid>

      <CommonModal
        title={actionModal.title}
        open={actionModal.open}
        onClose={onClose}
        maxWidth="xs"
      >
        {actionModal.payload && (
          <ActionModal
            onConfirm={multiActions}
            payload={actionModal.payload}
            onClose={onClose}
          />
        )}
      </CommonModal>

      {actionsModal?.details && (
        <DetailModal
          handleClose={onClose}
          id={actionsModal?.invoiceId}
          open={actionsModal?.details}
          invoicesURL={invoicesURL}
          initView={actionsModal?.detailsTab || 0}
          invoicesCountsURL={invoicesCountsURL}
          invoicesList={invoicesList?.results}
        />
      )}

      <DeleteModal {...actionsModalProps} />

      <DeleteModal
        open={isWarning.open}
        onClose={onClose}
        onConfirm={() =>
          isWarning.payload ? multiActions(isWarning.payload) : onClose()
        }
        description={isWarning.msg}
        loading={loading}
      />
    </Grid>
  )
}

export default InvoicesInfo
