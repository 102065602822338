import React, { useEffect, useMemo, useState, useReducer } from 'react'

import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import Select from '@components/common/Select'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import PlaceHolder from '@components/common/PlaceHolder'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import numberWithCommas from '@utils/numberWithCommas'
import { generateSuggestion } from '@api/invoices'
import { responseHandler } from '@utils/responseHandler'
import { useSnackbar } from 'notistack'
import { mutate } from 'swr'
import { format } from 'date-fns'
import FiltersElement from '@components/common/Filter/FiltersElement'
import { queriesReducer } from '@utils/commonReducerFunctions'
import formatURL from '@utils/formatURL'
import sleep from '@utils/sleep'
import { useTheme } from '@mui/material/styles'
import FilterActionsSelector from '@components/common/Filter/ActionsSelector'
import { Done, FileDownload } from '@mui/icons-material'
import downloadCSV from '@utils/downloadCSV'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { Stack } from '@mui/system'

export default function InvoiceTemplates(props) {
  const {
    operationId = null,
    fetchURL,
    invoicesCountsURL,
    currency,
    setIsGenerated,
    onClose
  } = props
  const { dictionary, t } = useTranslation()
  const classesTable = useTableStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const [templates, setTemplates] = useState([])
  const [checkedTemplates, setCheckedTemplates] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [sortedTemplates, setSortedTemplates] = useState([])

  const currentYear = new Date().getFullYear()
  const months = [
    { value: '01', label: t('month_names.0') },
    { value: '02', label: t('month_names.1') },
    { value: '03', label: t('month_names.2') },
    { value: '04', label: t('month_names.3') },
    { value: '05', label: t('month_names.4') },
    { value: '06', label: t('month_names.5') },
    { value: '07', label: t('month_names.6') },
    { value: '08', label: t('month_names.7') },
    { value: '09', label: t('month_names.8') },
    { value: '10', label: t('month_names.9') },
    { value: '11', label: t('month_names.10') },
    { value: '12', label: t('month_names.11') }
  ]
  const trimesters = [
    { value: 'q1', label: t('trimester_names.0') },
    { value: 'q2', label: t('trimester_names.1') },
    { value: 'q3', label: t('trimester_names.2') },
    { value: 'q4', label: t('trimester_names.3') }
  ]
  const yearsFormat = Array.from(
    { length: 16 },
    (_, index) => currentYear - 10 + index
  )

  // Map the array to the desired format
  const years = yearsFormat?.map(year => ({
    value: `${year}`,
    label: `${year}`
  }))

  const [queries, setQueries] = useState({
    frequency: 'monthly',
    period_start_date: format(new Date(), 'dd-MM-yyyy')
  })

  const [filterQueries, dispatchFilterQueries] = useReducer(queriesReducer, {})

  const schema = yup.object().shape({
    operation: yup.string().required(dictionary.required)
  })

  const {
    control,
    formState: { errors },
    watch
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      month: months[0].value,
      trimester: trimesters[0].value,
      year: currentYear,
      month_year: currentYear,
      trimester_year: currentYear
    }
  })

  const watchMonth = watch('month')
  const watchMonthYear = watch('month_year')
  const watchTrimester = watch('trimester')
  const watchTrimesterYear = watch('trimester_year')
  const watchYear = watch('year')
  const watchFrequency = watch('frequency')
  const watchInvoiceDate = watch('invoice_date')

  const templatesURL = formatURL(API_URL.INVOICES.GET_TEMPLATES(), {
    ...queries,
    ...filterQueries
  })

  const { data: templatesData, isLoading: isTemplatesLoading } =
    useFetchData(templatesURL)

  useEffect(() => {
    if (templatesData) {
      setTemplates(templatesData)
    }
  }, [templatesData])

  useEffect(() => {
    if (watchFrequency === 'monthly') {
      setQueries({
        ...queries,
        operation: operationId ? operationId : null,
        period_start_date: format(
          // new Date(`${watchMonth}-01-${watchMonthYear}`),
          new Date(watchMonthYear, watchMonth - 1, 1),
          'dd-MM-yyyy'
        ),
        frequency: 'monthly'
      })
    } else if (watchFrequency === 'quarterly') {
      const trimester = trimesters?.find(t => t.value === watchTrimester)
      const trimesterIndex = trimesters.indexOf(trimester)
      const startMonth = trimesterIndex * 3
      setQueries({
        ...queries,
        operation: operationId ? operationId : null,
        period_start_date: format(
          // new Date(`${months[startMonth]?.value}-01-${watchTrimesterYear}`),
          new Date(watchTrimesterYear, months[startMonth]?.value - 1, 1),
          'dd-MM-yyyy'
        ),
        frequency: 'quarterly'
      })
    } else if (watchFrequency === 'annualy') {
      setQueries({
        ...queries,
        operation: operationId ? operationId : null,
        period_start_date: format(new Date(watchYear, 0, 1), 'dd-MM-yyyy'),
        // format(new Date(`01-01-${watchYear}`), 'dd-MM-yyyy'),
        frequency: 'annualy'
      })
    } else {
      setQueries({
        ...queries,
        operation: operationId ? operationId : null,
        period_start_date: format(new Date(), 'dd-MM-yyyy'),
        frequency: 'monthly'
      })
    }
  }, [
    watchFrequency,
    watchMonth,
    watchTrimester,
    watchYear,
    watchMonthYear,
    watchTrimesterYear,
    operationId
  ])

  const handleSuggestionsClick = () => {
    setShowSuggestions(true)
  }

  const handleGenerateInvoice = async templateId => {
    const template = templates?.find(t => t.id === templateId)
    if (template) {
      const start_date_parts = template?.start_date?.split('-')
      const formattedStartDate = `${start_date_parts?.[2]}-${start_date_parts?.[1]}-${start_date_parts?.[0]}`
      const payload = {
        frequency: watchFrequency,
        id: templateId,
        leases: [template?.lease_id],
        period_start_date: formattedStartDate,
        type: template?.type
      }
      if (watchInvoiceDate) {
        payload.invoice_date = format(watchInvoiceDate, 'yyyy-MM-dd')
      }
      const response = await generateSuggestion(payload)

      responseHandler({
        response,
        callback: async () => {
          await sleep(500)
          await mutate(fetchURL)
          await mutate(invoicesCountsURL)
          await mutate(templatesURL)
          setIsGenerated(true)
          // setItems(prev => prev.filter(i => i.pk !== confirmModal.id))
        },
        dictionary,
        msgSuccess: dictionary.invoice_generated,
        snackbar: enqueueSnackbar
      })
    }

    onClose?.()
  }
  const generateInvoicesForCheckedTemplates = async () => {
    for (const checkedTemplate of checkedTemplates) {
      await handleGenerateInvoice(checkedTemplate)
    }
  }

  const handleSelectItem = id => {
    const isChecked = checkedTemplates?.includes(id)
    if (isChecked) {
      setCheckedTemplates(prev => prev.filter(i => i !== id))
    } else {
      setCheckedTemplates(prev => [...prev, id])
    }
  }

  const handleAllSelectItem = () => {
    const isChecked =
      checkedTemplates?.length ===
      templates?.filter(template => template?.id)?.length
    if (isChecked) {
      setCheckedTemplates([])
    } else {
      setCheckedTemplates(
        templates?.filter(template => template?.id)?.map(t => t.id)
      )
    }
  }

  useEffect(() => {
    setSortedTemplates(
      templates?.sort((a, b) => {
        if (a.already_billed && !b.already_billed) {
          return 1
        }
        if (!a.already_billed && b.already_billed) {
          return -1
        }
        return 0
      })
    )
  }, [templates])

  const downloadData = useMemo(() => {
    return sortedTemplates?.map((template, idx) => {
      return {
        [t('id')]: idx + 1,
        [t('operation')]: template.operation,
        [t('tenant')]: template.tenant,
        [t('type')]: template.type,
        [t('billing_entity')]: template.billing_entity,
        [t('start_date')]: template.start_date,
        [t('end_date')]: template.end_date,
        [t('total_bt')]: numberWithCommas(template.subtotal, 2, currency),
        [t('tax')]: numberWithCommas(template.tax, 2, currency),
        [t('total_at')]: numberWithCommas(template.total, 2, currency)
      }
    })
  }, [sortedTemplates, t])

  const options = [
    { label: t('actions') },
    {
      icon: <FileDownload color="secondary" />,
      label: t('export_csv'),
      disabled: !downloadData?.length,
      handleClick: () => downloadCSV(downloadData, t('invoices'))
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Select
              fullWidth
              name="frequency"
              label={dictionary.frequency}
              variant="outlined"
              control={control}
              defaultValue={'monthly'}
              error={Boolean(errors.frequency)}
              errorMessage={errors.frequency && errors.frequency.message}
            >
              <MenuItem key={'monthly'} value={'monthly'}>
                {t('monthly')}
              </MenuItem>
              <MenuItem key={'quarterly'} value={'quarterly'}>
                {t('quarterly')}
              </MenuItem>
              <MenuItem key={'annualy'} value={'annualy'}>
                {t('annually')}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            {watchFrequency === 'monthly' && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    name="month_year"
                    label={dictionary.year}
                    variant="outlined"
                    control={control}
                    error={Boolean(errors.month_year)}
                    errorMessage={
                      errors.month_year && errors.month_year.message
                    }
                  >
                    {years.map(year => (
                      <MenuItem key={year.value} value={year.value}>
                        {year.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    name="month"
                    label={dictionary.month}
                    variant="outlined"
                    control={control}
                    defaultValue={months[0].value}
                    error={Boolean(errors.month)}
                    errorMessage={errors.month && errors.month.message}
                  >
                    {months.map(month => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            )}
            {watchFrequency === 'quarterly' && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    name="trimester_year"
                    label={dictionary.year}
                    variant="outlined"
                    control={control}
                    error={Boolean(errors.trimester_year)}
                    errorMessage={
                      errors.trimester_year && errors.trimester_year.message
                    }
                  >
                    {years.map(year => (
                      <MenuItem key={year.value} value={year.value}>
                        {year.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    name="trimester"
                    label={dictionary.trimester}
                    variant="outlined"
                    control={control}
                    defaultValue={trimesters[0].value}
                    error={Boolean(errors.trimester)}
                    errorMessage={errors.trimester && errors.trimester.message}
                  >
                    {trimesters.map(trimester => (
                      <MenuItem key={trimester.value} value={trimester.value}>
                        {trimester.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            )}
            {watchFrequency === 'annualy' && (
              <Select
                fullWidth
                name="year"
                label={dictionary.year}
                variant="outlined"
                control={control}
                defaultValue={years[0].value}
                error={Boolean(errors.year)}
                errorMessage={errors.year && errors.year.message}
              >
                {years.map(year => (
                  <MenuItem key={year.value} value={year.value}>
                    {year.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          {!showSuggestions && (
            <Grid item xs={12} textAlign="right">
              <Button onClick={handleSuggestionsClick}>
                {t('invoicing_invoices_display_suggestions')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {showSuggestions && (
        <>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}
          >
            {!operationId ? (
              <FiltersElement
                handleQueries={dispatchFilterQueries}
                defaultQueries={{}}
                billing_entity={filterQueries?.billing_entity || ''}
                tenant={filterQueries?.tenant || ''}
                operation={filterQueries?.operation || ''}
                invoice_type={filterQueries?.type || ''}
              />
            ) : (
              <FiltersElement
                handleQueries={dispatchFilterQueries}
                defaultQueries={{}}
                billing_entity={filterQueries?.billing_entity || ''}
                tenant={filterQueries?.tenant || ''}
                invoice_type={filterQueries?.type || ''}
              />
            )}
            <FilterActionsSelector isMenu options={options} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer className={classesTable.container}>
              <Table>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell
                      className={classesTable.headerCell}
                      align="center"
                    >
                      <Checkbox
                        size="small"
                        disabled={isTemplatesLoading}
                        checked={
                          checkedTemplates?.length ===
                            templates?.filter(template => template?.id)
                              ?.length &&
                          templates?.filter(template => template?.id)?.length >
                            0
                        }
                        onChange={() => handleAllSelectItem()}
                        sx={{ color: theme => theme.palette.text.extralight }}
                      />
                    </TableCell>
                    <TableCell className={classesTable.headerCell} />
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.operation}
                    </TableCell>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.tenant}
                    </TableCell>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.type}
                    </TableCell>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.billing_entity}
                    </TableCell>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.start_date}
                    </TableCell>
                    <TableCell className={classesTable.headerCell}>
                      {dictionary.end_date}
                    </TableCell>
                    <TableCell
                      className={classesTable.headerCell}
                      align="right"
                    >
                      {t('total_bt')}
                    </TableCell>
                    <TableCell
                      className={classesTable.headerCell}
                      align="right"
                    >
                      {t('tax')}
                    </TableCell>
                    <TableCell
                      className={classesTable.headerCell}
                      align="right"
                    >
                      {t('total_at')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isTemplatesLoading && (
                    <TableRow className={classesTable.row}>
                      <TableCell align="center" colSpan={'100%'}>
                        <CircularProgress color="secondary" />
                      </TableCell>
                    </TableRow>
                  )}

                  {!isTemplatesLoading && templates && !templates.length && (
                    <TableRow className={classesTable.row}>
                      <TableCell align="center" colSpan={'100%'}>
                        <PlaceHolder
                          src="/Placeholders/Project_More_Contacts.png"
                          width={'500px'}
                          height={'450px'}
                        />
                      </TableCell>
                    </TableRow>
                  )}

                  {!isTemplatesLoading &&
                    sortedTemplates?.length > 0 &&
                    sortedTemplates?.map((template, idx) => (
                      <Tooltip
                        key={idx}
                        title={
                          template?.already_billed
                            ? t('invoicing_invoices_already_generated')
                            : ''
                        }
                      >
                        <TableRow hover={false}>
                          <TableCell align="center">
                            {template?.already_billed ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Done
                                  sx={{
                                    fontSize: 15,
                                    color: theme.palette.text.extralight
                                  }}
                                />
                              </div>
                            ) : (
                              <Checkbox
                                size="small"
                                disabled={isTemplatesLoading}
                                checked={checkedTemplates?.includes(
                                  template.id
                                )}
                                onChange={() => handleSelectItem(template.id)}
                                sx={{
                                  color: theme => theme.palette.text.extralight,
                                  '&.Mui-checked': {
                                    opacity: 0.9
                                  },
                                  '&.Mui-disabled': {
                                    opacity: 0,
                                    pointerEvents: 'none'
                                  }
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: theme.palette.text.disabled,
                              fontSize: 10
                            }}
                          >
                            {idx + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {template.operation}
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {template.tenant}
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {template.type}
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {template.billing_entity}
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {template.start_date}
                          </TableCell>
                          <TableCell
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {template.end_date}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {numberWithCommas(template.subtotal, 2, currency)}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {numberWithCommas(template.tax, 2, currency)}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              color:
                                template?.already_billed &&
                                theme.palette.text.extralight
                            }}
                          >
                            {numberWithCommas(template.total, 2, currency)}
                          </TableCell>
                          {/* <TableCell
                            align="right"
                            className={classesTable.rowActions}
                          >
                            <ActionsPopover actions={ACTIONS(template.id)} />
                          </TableCell> */}
                        </TableRow>
                      </Tooltip>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} textAlign="right">
            <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
              <Controller
                name="invoice_date"
                control={control}
                render={({ field }) => (
                  <DesktopDatePicker
                    {...field}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        size="medium"
                        textFieldSize="small"
                        inputFormat="dd/MM/yyyy"
                        margin="normal"
                        label={dictionary.invoice_date}
                        sx={{ width: '195px' }}
                      />
                    )}
                  />
                )}
              />
              <Button onClick={() => generateInvoicesForCheckedTemplates()}>
                {t('invoicing_invoices_generate_invoices')}
              </Button>
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  )
}
