import React, { useState } from 'react'
import { Paper, InputBase, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { useTranslation } from '@contexts/translation'

const CustomSearchInput = ({ value, handleChange }) => {
  const { dictionary } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setIsOpen(!isOpen)
  }

  return (
    <Paper
      component="form"
      sx={{
        height: '37px',
        borderRadius: t => t.spacing(2),
        p: '6px 0 6px 5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 250,
        border: t => `1px solid ${t.palette.other.stroke}`,
        transition: 'all 300ms ease'
      }}
      onSubmit={handleSubmit}
    >
      <IconButton
        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        data-testid="IconButton-8eec3107-c011-46b1-a417-d2be893e7bc5"
      >
        <SearchIcon sx={{ color: 'text.light' }} />
      </IconButton>
      <InputBase
        sx={{ flex: 1, color: t => t.palette.text.primary }}
        placeholder={dictionary.search}
        value={value}
        onChange={handleChange}
        data-testid="InputBase-3ae1f35d-1861-47d9-90f8-45221d2546e3"
      />
    </Paper>
  )
}

export default CustomSearchInput
