import { useState } from 'react'
import {
  Grid,
  Button,
  Stack,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  CircularProgress,
  Typography
} from '@mui/material'

import PropTypes from 'prop-types'

import { useTranslation } from '@contexts/translation'

import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import { Person } from '@mui/icons-material'
import { updateInvoice } from '@api/invoices'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { responseHandler } from '@utils/responseHandler'
import formatURL from '@utils/formatURL'

const CreateCreditNote = ({
  onClose,
  invoicesCountsURL,
  openInvoice,
  leaseId,
  operationId
}) => {
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState(null)

  const invoicesURL = formatURL(API_URL.INVOICES.GET_INVOICES_LIST_PK(), {
    operation: operationId,
    lease: leaseId,
    status: 'open',
    page_size: 99999
  })
  const { data: openInvoicesList, isLoading } = useFetchData(invoicesURL)

  const onConfirmCreditNote = async () => {
    setLoading(true)
    const response = await updateInvoice(selectedInvoice, { is_closed: true })

    responseHandler({
      response,
      callback: async () => {
        await mutate(invoicesURL)
        await mutate(invoicesCountsURL)
        if (response?.data.credit_note_url) {
          const creditNoteURL = response?.data.credit_note_url.split('/') || []
          const creditNoteId = creditNoteURL[creditNoteURL?.length - 2]
          openInvoice && openInvoice(creditNoteId)
        }
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    onClose()
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <List dense={true}>
          <Stack spacing={1} alignItems="center">
            {openInvoicesList &&
              openInvoicesList.results.map(i => (
                <ListItem
                  key={i.pk}
                  disablePadding
                  sx={{
                    height: t => t.spacing(5),
                    border: theme => `1px solid ${theme.palette.other.stroke}`,
                    borderRadius: theme => theme.spacing(1),
                    bgcolor:
                      selectedInvoice === i.pk
                        ? t => t.palette.primary.xtraLight
                        : ''
                  }}
                >
                  <ListItemButton
                    onClick={() => setSelectedInvoice(i.pk)}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                    data-testid="ListItemButton-782d8210-ecb5-4a66-97ba-7a823da3b789"
                  >
                    <span data-testid="span-48d9a6b4-0d47-464f-9021-26cc2539ee9b">
                      {i.code}
                    </span>
                    <span data-testid="span-a4e28b42-5160-43f4-9b76-ef7bf9d9c4b8">
                      {i.project ? (
                        i.project_display
                      ) : (
                        <Stack direction="row" alignItems="center">
                          {i.client_name}
                          <Tooltip title={dictionary.client_name}>
                            <Person
                              sx={{ ml: 1 }}
                              fontSize="small"
                              color="secondary"
                            />
                          </Tooltip>
                        </Stack>
                      )}
                    </span>
                  </ListItemButton>
                </ListItem>
              ))}

            {isLoading && <CircularProgress size={25} />}
            {!isLoading && !openInvoicesList?.results?.length && (
              <Typography data-testid="Typography-325ae3c4-ecd6-4635-8ec3-6bc6c448f593">
                {dictionary.no_open_invoice}
              </Typography>
            )}
          </Stack>
        </List>
      </Grid>

      <Grid item xs={12} align="right">
        <Button
          onClick={onConfirmCreditNote}
          disabled={loading || !selectedInvoice}
          data-testid="Button-6f3e8ab3-112d-4814-b28f-238b316d5f78"
        >
          {dictionary.create}
        </Button>
      </Grid>
    </Grid>
  )
}

export default CreateCreditNote

CreateCreditNote.propTypes = {
  onClose: PropTypes.func,
  invoicesCountsURL: PropTypes.string,
  openInvoice: PropTypes.func,
  leaseId: PropTypes.number,
  operationId: PropTypes.string
}
