import { ExportToCsv } from 'export-to-csv'

const downloadCSV = (data, title) => {
  const options = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: false,
    showTitle: false,
    title: title,
    filename: title,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  }
  const csvExporter = new ExportToCsv(options)
  csvExporter.generateCsv(data)
}

export default downloadCSV
